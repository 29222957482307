import { Link } from "react-router-dom";
const contentRu = {
    mainPage: {
        pageName: "Найдется главное",
        subMenuList: [
            { num: 1, label: "Для бизнеса", anchor: "MainPageForBusiness" },
            { num: 2, label: "Безопасность", anchor: "MainPageSafety" },
            { num: 3, label: "Платформа", anchor: "MainPagePlatform" },
            { num: 4, label: "О нас", anchor: "MainPageAboutUs" },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/mainPage/img1.jpg",
            img1Mobile: "./img/mainPage/img1Mobile.jpg",
            img1Tablet: "./img/mainPage/img1Tablet.jpg", // добавил главную картинку
            img2: "./img/mainPage/img2.jpg",
            img2Mobile: "./img/mainPage/img2Mobile.jpg",
            img2Tablet: "./img/mainPage/img2Tablet.jpg",
            img3: "./img/mainPage/img3.jpg",
            img3Mobile: "./img/mainPage/img3Mobile.jpg",
            img3Tablet: "./img/mainPage/img3Tablet.jpg"
        },
        blocks: {
            forBusiness: {
                num: 1,
                name: "Для бизнеса",
                textBlock1:
                    "С 2016 года наш цифровой ситуационный центр ведет комплексный анализ информационного пространства: мы контролируем повестку СМИ, социальных сетей, мессенджеров, а также информацию в darkweb. Мы обеспечиваем эффективное взаимодействие с целевыми аудиториями, защищаем бизнес от информационных атак, обеспечиваем раннее предупреждение и ликвидацию разнообразных рисков и угроз в режиме реального времени.",
                textBlock2:
                    "Аутсорсинг экономической безопасности. Аудит репутации и раннее обнаружение угроз устойчивому развитию. Мониторинг конкурентов и внешней среды, а также персонализированная медиааналитика.",
                linkName1: "Подробнее",
                link1: "/forBusinessPage",
                anchor: "MainPageForBusiness"
            },
            safety: {
                num: 2,
                name: "Безопасность",
                textBlock1:
                    "Информационная безопасность для бизнеса является одним из ключевых аспектов успешной работы, поскольку она защищает конфиденциальные данные и интеллектуальную собственность. Интеграция современных решений в области информационной безопасности сегодня является обязательным элементом стратегии устойчивого развития бизнеса, так как это позволяет предотвращать экономические потери, вызванные кибератаками и утечками данных.",
                textBlock2:
                    "Компании и регулирующие органы по всему миру вводят все более строгие требования к защите информации, а игнорирование подобных мер может привести к серьезным юридическим и финансовым последствиям.",
                linkName1: "Подробнее",
                link1: "/safetyPage",
                anchor: "MainPageSafety"
            },
            platform: {
                num: 3,
                name: "Платформа",
                textBlock1:
                    "Продукты на базе технологической платформы Avalanche обеспечивают Заказчика возможностями современного ситуационного центра в части сбора, обработки, накопления и анализа больших объемов данных из открытых источников.",
                textBlock2:
                    "Использование продуктов Avalanche существенно повышает осведомленность руководтства о важных событиях за счет внедрения новых высокотехнологичных решений, обеспечивающих непрерывный автоматизированный анализ потока данных из открытых источников.",
                linkName1: "Подробнее",
                link1: "/platformPage",
                anchor: "MainPagePlatform"
            },
            situationCenter: {
                name: "Ситуационный центр",
                textBlock1:
                    "Аутсорсинг ситуационного центра. Обеспечение круглосуточного мониторинга информационного поля вокруг объекта интереса и выявление возникающих рисков и угроз без создания собственного ситуационного центра, взяв в аренду наших специалистов и технические решения.",
                textBlock2:
                    "Программные средства в автоматизированном режиме выбирают наиболее важную информацию и, применяя предварительную обработку и логико-синтаксический анализ текстов, представляют её в максимально удобном виде, а также формируют статистические и аналитические отчёты по самым важным событиям.",
                linkName: "Подробнее",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Анализ аудитории",
                textBlock1:
                    "Avalanche помогает быстро собирать и обрабатывать большие объемы данных из социальных медиа. Незаменимый инструмент для специалистов самого разного профиля — от PR и маркетинга до корпоративной разведки. Анализ целевой аудитории, прирост подписчиков, их активность и многое другое.",
                textBlock2:
                    "Осуществляется анализ открытых данных в социальных медиа, без нарушения приватности пользователей. Позволяет выявлять инофрмационные волны и атаки фейковых аккаунтов. Обеспечивается оперативность получения данных, прозрачность и объективность информации.",
                linkName: "Подробнее",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Сканирование уязвимостей",
                textBlock1:
                    "Современные злоумышленники обнаруживают и эксплуатируют все новые уязвимости на веб-ресурсах, которые содержат чувствительные данные. Ошибки программной реализации, несвоевременное обновление программного обеспечения, устаревшие пароли и многое другое, все это приводит к появлению серьезных уязвимостей в интернет-проектах.",
                textBlock2:
                    "Веб-сканер - это набор решений, направленных на обеспечение функций контроля защищенности информационной системы, построенной на базе веб-технологий. Веб-сканер позволяет реализовать функции обнаружения, предотвращения и реагирования на попытки несанкционированной активности удаленных и внутренних пользователей компьютерных сетей.",
                linkName: "Подробнее",
                link: "/scannerPage"
            },
            analytics: {
                name: "Аналитика",
                textBlock1:
                    "Контроль информационной повестки и варианты решения возникающих проблем, реагирования на риски и угрозы, противодействия информационным атакам. Оценка эффективности стратегических коммуникаций, рекламных кампаний и иных публичных взаимодействий с целевой аудиторией и репозиционирование по уязвимым вопросам.",
                textBlock2:
                    "Кабинетные исследования в интересах служб маркетинга, PR и экономической безопасности. Аналитика работает по принципу справочного бюро и помогает найти ответы на самые сложные вопросы.",
                linkName: "Подробнее",
                link: "/analyticsPage"
            },
            unnamedBlock: {
                textBlock:
                    "Импортозамещение иностранных производителей и развитие российской конкурентоспособности с целью парирования санкционных рисков в сфере высоких технологий"
            },
            aboutUs: {
                num: 4,
                name: "О нас",
                textBlock1:
                    "Впервые продукт под названием Avalanche появился на российском рынке в 2003 году. Его создатель – первопроходец на рынке конкурентной разведки, подполковник ФАПСИ в отставке, Андрей Масалович. На протяжении почти 20 лет система использовалась для контроля общественных настроений и борьбы с террористической угрозой силовыми ведомствами, федеральными и региональными властями. ",
                textBlock2:
                    "Сегодня, аналитические системы, построенные на базе технологической платформы Avalanche активно используются коммерческими компаниями для мониторинга российских и иностранных рынков, партнеров и конкурентов.",
                linkName1: "Подробнее",
                link1: "/aboutUsPage",
                anchor: "MainPageAboutUs"
            },
            shortBlock: {
                name: "Синергия технологий анализа больших данных"
            },
            demonstration: {
                num: "",
                name: "Демонстрация",
                textBlock2: `Для демонстрации работы Avalanche мы преднастроили несколько проектов:\n\r\u2022  политические лидеры в иностранных СМИ \n\r\u2022  министерства и ведомства в российских СМИ \r\n\u2022  COVID-19 в регионах России \r\nДля получения дополнительной информации свяжитесь с нами.`
            },
            sixCardsBlock: [
                {
                    num: 1,
                    name: "Политические лидеры",
                    text: "Упоминания ключевых мировых политических лидеров в иностранных СМИ.",
                    linkName: "Перейти",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Министерства",
                    text: "Повестка российских министерств и ведомств в отечественных СМИ.",
                    linkName: "Перейти",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Упоминания коронавирусной инфекции в региональных СМИ России, разбитые на федеральные округа.",
                    linkName: "Перейти",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Поиск авиабилетов",
                    text: "Сбор информации о дешевых перелетах и акциях различных мировых авиакомпаний.",
                    linkName: "Перейти",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Новости мобилизации",
                    text: "Все самое важное о мобилизационной кампании и волонтерских миссиях.",
                    linkName: "Перейти",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersec",
                    text: "Новости из мира разведки и кибербезопасности, мониторинг утечек и поиск уязвимостей.",
                    linkName: "Перейти",
                    externalLink: "https://osint.avl.team/"
                }
            ],
            cardsWithImg: [
                {
                    num: 1,
                    text: "Люди. Успех достигается упорным трудом нашей профессиональной  команды. Avalanche это комплекс, эксплуатируемый квалифицированным техническим персоналом в составе: операторов, аналитиков и системных администраторов.",
                    imgLink: "./img/mainPage/small1.jpg"
                },
                {
                    num: 2,
                    text: "Процессы. Формирование и анализ карты рисков и угроз репутации. Определение сценариев возможных информационных атак. Выработка механизмов контроля аудитории в информационном поле и оптимизация работы со СМИ.",
                    imgLink: "./img/mainPage/small2.jpg"
                },
                {
                    num: 3,
                    text: "Технологии. Расширенный набор современных алгоритмов для обработки больших данных. ",
                    imgLink: "./img/mainPage/small3.jpg"
                }
            ],
            cardsBlock: {
                cards: [
                    {
                        num: 1,
                        text: "Люди. Успех достигается упорным трудом нашей профессиональной  команды. ",
                        imgLink: "./img/mainPage/small1Mobile.jpg"
                    },
                    {
                        num: 2,
                        text: "Процессы. Формирование и анализ карты рисков и угроз репутации. ",
                        imgLink: "./img/mainPage/small2Mobile.jpg"
                    },
                    {
                        num: 3,
                        text: "Технологии. Расширенный набор современных алгоритмов для обработки больших данных. ",
                        imgLink: "./img/mainPage/small3Mobile.jpg"
                    }
                ]
            }
        }
    },
    platform: {
        pageName: "Платформа",
        subMenuList: [
            { num: 1, label: "Платформа", anchor: "PlatformPagePlatform" },
            { num: 2, label: "Наши продукты", anchor: "PlatformPageProducts" },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/platformPage/img1.jpg",
            img1Mobile: "./img/platformPage/img1Mobile.jpg"
        },
        blocks: {
            imageBlock: {
                name: "",
                textBlock1: "",
                textBlock2:
                    "Avalanche («Лавина») – это технология интернет-разведки и построенное на ее основе семейство автоматизированных систем интернет-мониторинга нового поколения, объединяющее в себе расширенные возможности сбора и аналитической обработки информации, а также оперативного планирования мероприятий.",
                linkName1: "",
                link1: "",
                anchor: "PlatformPagePlatform"
            },
            ourProducts: {
                num: 2,
                name: "Наши продукты",
                anchor: "PlatformPageProducts"
            },
            situationCenter: {
                name: "Ситуационный центр",
                textBlock1:
                    "Аутсорсинг ситуационного центра. Обеспечение круглосуточного мониторинга информационного поля вокруг объекта интереса и выявление возникающих рисков и угроз без создания собственного ситуационного центра, взяв в аренду наших специалистов и технические решения.",
                textBlock2:
                    "Программные средства в автоматизированном режиме выбирают наиболее важную информацию и, применяя предварительную обработку и логико-синтаксический анализ текстов, представляют её в максимально удобном виде, а также формируют статистические и аналитические отчёты по самым важным событиям.",
                linkName: "Подробнее",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Анализ аудитории",
                textBlock1:
                    "Avalanche помогает быстро собирать и обрабатывать большие объемы данных из социальных медиа. Незаменимый инструмент для специалистов самого разного профиля — от PR и маркетинга до корпоративной разведки. Анализ целевой аудитории, прирост подписчиков, их активность и многое другое.",
                textBlock2:
                    "Осуществляется анализ открытых данных в социальных медиа, без нарушения приватности пользователей. Позволяет выявлять инофрмационные волны и атаки фейковых аккаунтов. Обеспечивается оперативность получения данных, прозрачность и объективность информации.",
                linkName: "Подробнее",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Сканирование уязвимостей",
                textBlock1:
                    "Современные злоумышленники обнаруживают и эксплуатируют все новые уязвимости на веб-ресурсах, которые содержат чувствительные данные. Ошибки программной реализации, несвоевременное обновление программного обеспечения, устаревшие пароли и многое другое, все это приводит к появлению серьезных уязвимостей в интернет-проектах.",
                textBlock2:
                    "Веб-сканер - это набор решений, направленных на обеспечение функций контроля защищенности информационной системы, построенной на базе веб-технологий. Веб-сканер позволяет реализовать функции обнаружения, предотвращения и реагирования на попытки несанкционированной активности удаленных и внутренних пользователей компьютерных сетей.",
                linkName: "Подробнее",
                link: "/scannerPage"
            },
            analytics: {
                name: "Аналитика",
                textBlock1:
                    "Контроль информационной повестки и варианты решения возникающих проблем, реагирования на риски и угрозы, противодействия информационным атакам. Оценка эффективности стратегических коммуникаций, рекламных кампаний и иных публичных взаимодействий с целевой аудиторией и репозиционирование по уязвимым вопросам.",
                textBlock2:
                    "Кабинетные исследования в интересах служб маркетинга, PR и экономической безопасности. Аналитика работает по принципу справочного бюро и помогает найти ответы на самые сложные вопросы.",
                linkName: "Подробнее",
                link: "/analyticsPage"
            }
        },
        sixCardsBlock: [
            {
                num: 1,
                name: "Целевая настройка",
                text: "Тонкая настройка информационных ресурсов, с возможностью очистки значимой информации от несущественного контента, баннеров и т.п.. Сбор самого главного, отсекая нерелевантную информацию."
            },
            {
                num: 2,
                name: "Онлайн 24/7",
                text: "Мониторинг и оповещение в круглосуточном режиме. Даже ночью. Даже в праздники. Всегда на страже ваших интересов. Если будет обнаружена угроза – вы получите оповещение в Telegram."
            },
            {
                num: 3,
                name: "Интеграция",
                text: "Гибкие механизмы интеграции и обмена данными со сторонними информационными системами. Avalanche может выступать как в качестве источника данных, так и в качестве инструмента анализа. "
            },
            {
                num: 4,
                name: "Анализ и визуализация",
                text: "Анализ дублей и эмоциональная окраска. Визуализация связей и схемы распространения информации. Любые данные пригодны для визуализации."
            },
            {
                num: 5,
                name: "Обзор на 360 градусов",
                text: "Лента событий – это не только традиционные СМИ и телеграм-каналы, но еще и корпоративные события, суды, тендеры, правительственные сайты и многое другое. Вы получаете комплексный взгляд на события."
            },
            {
                num: 6,
                name: "Подсвечивает главное",
                text: "Серьезные инфоповоды и статьи в крупных СМИ и телеграм-каналах подсвечиваются зеленым, компромат и негатив – желтым. Критические поводы – красным. Система «светофор» работает на основе методов статистики и обучаемых нейронных сетей."
            }
        ],
        cardsBlock: {
            cards: [
                {
                    num: 1,
                    name: "Целевая настройка",
                    text: "Тонкая настройка информационных ресурсов, с возможностью очистки значимой информации от несущественного контента, баннеров и т.п.. Сбор самого главного, отсекая нерелевантную информацию."
                },
                {
                    num: 2,
                    name: "Онлайн 24/7",
                    text: "Мониторинг и оповещение в круглосуточном режиме. Даже ночью. Даже в праздники. Всегда на страже ваших интересов. Если будет обнаружена угроза – вы получите оповещение в Telegram."
                },
                {
                    num: 3,
                    name: "Интеграция",
                    text: "Гибкие механизмы интеграции и обмена данными со сторонними информационными системами. Avalanche может выступать как в качестве источника данных, так и в качестве инструмента анализа. "
                },
                {
                    num: 4,
                    name: "Анализ и визуализация",
                    text: "Анализ дублей и эмоциональная окраска. Визуализация связей и схемы распространения информации. Любые данные пригодны для визуализации."
                },
                {
                    num: 5,
                    name: "Обзор на 360 градусов",
                    text: "Лента событий – это не только традиционные СМИ и телеграм-каналы, но еще и корпоративные события, суды, тендеры, правительственные сайты и многое другое. Вы получаете комплексный взгляд на события."
                },
                {
                    num: 6,
                    name: "Подсвечивает главное",
                    text: "Серьезные инфоповоды и статьи в крупных СМИ и телеграм-каналах подсвечиваются зеленым, компромат и негатив – желтым. Критические поводы – красным. Система «светофор» работает на основе методов статистики и обучаемых нейронных сетей."
                }
            ]
        }
    },
    forBusiness: {
        pageName: "Для бизнеса",
        subMenuList: [
            {
                num: 1,
                label: "Конкурентная разведка для вашего бизнеса",
                anchor: "ForBusinessMain"
            },
            {
                num: 2,
                label: "Виртуальный ситуационный центр",
                anchor: "ForBusinessCenter"
            },
            {
                num: 3,
                label: "Лицензии и внедрение",
                anchor: "ForBusinessLicence"
            },
            {
                num: 4,
                label: "Услуги и аналитика",
                anchor: "ForBusinessService"
            },
            {
                num: 5,
                label: "Коммуникации",
                anchor: "ForBusinessCommunications"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/forBusinessPage/img1.jpg",
            img1Mobile: "./img/forBusinessPage/img1Mobile.jpg",
            img2: "./img/forBusinessPage/img2.jpg",
            img2Mobile: "./img/forBusinessPage/img2Mobile.jpg",
            img3: "./img/forBusinessPage/img3.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Конкурентная разведка для вашего бизнеса",
                textBlock2:
                    "Проекты в Avalanche обеспечивают полный контроль информационного пространства. С их помощью вы сможете отслеживать ситуацию на рынке, активность конкурентов, изменения в государственной политике и нормативно-правовом регулировании. Каждый проект уникален и настраивается под тематику отрасли. ",

                anchor: "ForBusinessMain"
            },
            center: {
                num: 2,
                name: "Виртуальный ситуационный центр",
                textBlock1: "",
                textBlock2:
                    "Сервис мониторинга интернет-пространства обеспечивает Заказчика возможностями современного ситуационного центра в части мониторинга оперативной обстановки и раннего обнаружения интернет-угроз. Для коммерческих Заказчиков предусмотрено 3 тарифных плана.",
                linkName1: "Тарифы",
                link1: "/tariffsPage",
                anchor: "ForBusinessCenter"
            },
            communications: {
                num: 5,
                name: "Коммуникации",
                textBlock1: "",
                textBlock2:
                    "Деловая репутация и доверие клиентов завоевываются годами, но могут быть разрушены моментально информационной атакой конкурентов. Любое публичное действие компании может стать мишенью для информационной агрессии – это стало частью суровых реалий российского бизнеса.",
                linkName1: "Подробнее",
                link1: "/communicationsPage",
                anchor: "ForBusinessCommunications"
            },
            service: {
                num: 4,
                name: "Услуги и аналитика",
                textBlock1: "",
                textBlock2:
                    "Эффективнее всего Avalanche работает в связке с нашими консалтинговыми услугами. Мы не только настроим проект под ваши информационные нужды, но и подготовим регулярную аналитику, просчитаем возможные риски и подсветим точки роста.",
                linkName1: "Подробнее",
                link1: "/analyticsPage",
                anchor: "ForBusinessService"
            },
            licence: {
                num: 3,
                name: "Лицензии и внедрение",
                textBlock1: "",
                textBlock2:
                    "Внедрение программного комплекса Avalanche на оборудовании Заказчика, включая начальную настройку и обучение пользователей. Управление мониторингом в компании, без ограничения числа пользователей и количества объектов наблюдения.",
                linkName1: "Тарифы",
                link1: "/tariffsPage",
                anchor: "ForBusinessLicence"
            }
        }
    },
    situationCenter: {
        pageName: "Ситуационный центр",
        subMenuList: [
            {
                num: 1,
                label: "Ситуационный центр",
                anchor: "SituationCenterMain"
            },
            {
                num: 2,
                label: "О ситуационных центрах",
                anchor: "SituationCenterAbout"
            },
            {
                num: 3,
                label: "Преимущества ситуационных центров",
                anchor: "SituationCenterBenifits"
            },
            {
                num: 4,
                label: "Аутсорсинг ситуационного центра",
                anchor: "SituationCenterOutsourse"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/situationCenter/img1.jpg",
            img1Mobile: "./img/situationCenter/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Ситуационный центр",
                textBlock2:
                    "С 2016 года наш ситуационный центр ведет комплексный мониторинг информационного пространства:мы контролируем повестку СМИ, социальных сетей, мессенджеров, информацию в darkweb.В ситуационном центре компании работает более 50 операторов и аналитиков, которые обеспечивают раннее выявление и предупреждение рисков и угроз в интересах федеральных органов государственной власти, госкорпораций, крупных коммерческих компаний, банковского сектора.",

                anchor: "SituationCenterMain"
            },
            about: {
                num: 2,
                name: "О ситуационных центрах",
                textBlock2:
                    "Ситуационный центр – это структура, созданная для оперативного наблюдения за информационной средой и консолидации информации для принятия грамотных управленческих решений. Обычно ситуационный центр состоит из трех компонентов: группы мониторинга, группы аналитиков и группы оперативного реагирования.",

                anchor: "SituationCenterAbout"
            },
            benifits: {
                num: 3,
                name: "Преимущества ситуационных центров",
                textBlock2:
                    "В случае экстренной ситуации или информационной атаки от оперативности принятия решения может зависеть репутация всей компании. Ключевая задача ситуационного центра – заранее предупредить о надвигающейся опасности, дать всю необходимую информацию о ситуации, а также предоставить информационные ресурсы для адекватной реакции.",

                anchor: "SituationCenterBenifits"
            },
            outsourse: {
                num: 4,
                name: "Аутсорсинг ситуационного центра",
                textBlock2:
                    "Создание и содержание полноценного ситуационного центра – очень затратное дело, даже для самых крупных компаний. Стоимость оборудования ситуационного центра начинается от 10 млн рублей, а стоимость содержания – от 1 млн рублей в месяц. Мы владеем собственным ситуационным центром с общим штатом более 50 человек и предлагает новую и уникальную для российского рынка услугу – аутсорсинг ситуационного центра.",

                anchor: "SituationCenterOutsourse"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Группа мониторинга",
                        text: "Контролирует информационное пространство, осуществляет поиск рисков и угроз компании, осуществляет непрерывное наблюдение за окружающей средой и конкурентами."
                    },
                    {
                        num: 2,
                        name: "Группа аналитики",
                        text: "Ведет оценку выявленных рисков и угроз, формирует понимание ситуации и дает стратегию реагирования."
                    },
                    {
                        num: 3,
                        name: "Группа оперативного реагирования",
                        text: "Обеспечивает нейтрализацию рисков и угроз, которые появились в СМИ, социальных сетях или мессенджерах."
                    },
                    {
                        num: 4,
                        name: "Координация усилий",
                        text: "Совместными усилиями группы обеспечивают комплексную безопасность всех сегментов информационного фона."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Постоянный контроль повестки",
                        text: "В отличие от штатного PR-отдела, ситуационный центр компании будет обеспечивать постоянный и круглосуточный мониторинг информационного пространства, даже ночью и в выходные дни."
                    },
                    {
                        num: 2,
                        name: "Раннее оповещение",
                        text: "Операторы ситуационного центра получают информацию первыми, как только она только вышла в публичное пространство и еще не получила распространение. Поэтому у наших клиентов всегда достаточно времени для превентивного реагирования на угрозу. Оповещение о выявленных угрозах идет в режиме реального времени."
                    },
                    {
                        num: 3,
                        name: "Разработка противодейстия",
                        text: "Оценка рисков, сбор и анализ данных для принятия оптимального управленческого решения и разработки стратегии противодействия угрозам."
                    },
                    {
                        num: 4,
                        name: "Реагирование",
                        text: "Совместными усилиями группы обеспечивают комплексную безопасность всех сегментов информационного фона."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Оптимальная стоимость",
                        text: "Компания получит полный набор услуг ситуационного центра без расходов на его создания и трат на содержание."
                    },
                    {
                        num: 2,
                        name: "Маcштаби-руемость",
                        text: "Ситуационный центр может работать как для небольших компаний, так и для крупного бизнеса, ведущего международную деятельность."
                    },
                    {
                        num: 3,
                        name: "Гибкость",
                        text: "Сотрудники ситуационного центра погружаются в процессы и проблемы отрасли и могут работать в различных областях."
                    },
                    {
                        num: 4,
                        name: "Быстрое развертывание",
                        text: "Ситуационный центр развертывается и настраивается на новую тему в течение недели. Дает гибкость и адаптивность при принятии решений."
                    }
                ]
            }
        }
    },
    communications: {
        pageName: "Коммуникации",
        subMenuList: [
            {
                num: 1,
                label: "Коммуникации",
                anchor: "CommunicationsMain"
            },
            {
                num: 2,
                label: "Защита от информационной агрессии",
                anchor: "CommunicationsDefence"
            },
            {
                num: 3,
                label: "Управление репутацией",
                anchor: "CommunicationsReputation"
            },
            {
                num: 4,
                label: "Формирование информационного фона​",
                anchor: "CommunicationsBackground"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/communicationsPage/img1.jpg",
            img1Mobile: "./img/communicationsPage/img1Mobile.jpg",
            img2Mobile: "./img/communicationsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Коммуникации",
                textBlock2:
                    "Деловая репутация и доверие клиентов завоевываются годами, но могут быть разрушены моментально информационной атакой конкурентов. Любое публичное действие компании может стать мишенью для информационной агрессии – это стало частью суровых реалий российского бизнеса.",

                anchor: "CommunicationsMain"
            },
            defence: {
                num: 2,
                name: "Защита от информационной агрессии",
                textBlock1:
                    "Компании конкурируют за потребителя не только на рынке, но и в информационном пространстве. Любое изменение рыночной среды или публичное действие компании может привести к началу разрушительной информационной атаки со стороны конкурентов.",
                textBlock2:
                    "Мы обеспечиваем защиту и предупреждение информационной агрессии и не только круглосуточно контролируем информационное пространство, но и помогаем предотвратить репутационные потери.Для каждого клиента мы разрабатываем уникальную карту рисков и угроз для понимания возможных целей и источников атаки, а также помогаем дать адекватный ответ на информационную агрессию в СМИ, социальных сетях и мессенджерах.",

                anchor: "CommunicationsDefence"
            },
            reputation: {
                num: 3,
                name: "Управление репутацией",
                textBlock2:
                    "Помощь в восстановлении деловой репутации в средствах массовой информации, удаление или блокировка противоправной информации, а также оказание содействия по защите интересов.",

                anchor: "CommunicationsReputation"
            },
            background: {
                num: 4,
                name: "Формирование информационного фона",
                textBlock2:
                    "Предупреждать проще и дешевле, чем лечить. Формирование позитивного информационного фона в отношении компании позволит резко сократить расходы, связанные с информационной борьбой. Продвижение позитивной информационной повестки - важное условие для формирования благоприятной ифнормационной среды.",

                anchor: "CommunicationsBackground"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Время",
                        text: "Главный ресурс во время кризиса – время. PR-отделы и подрядчики чаще всего упускают начало информационной атаки и теряют время."
                    },
                    {
                        num: 2,
                        name: "Аутсорсинг",
                        text: "Наша компания первой в России предоставляет услуги по аутсорсингу ситуационного центра. Это значит, что информационный фон о компании будет под постоянным контролем круглые сутки и без выходных. Наблюдение ведут квалифицированные аналитики, которые определяют риски и угрозы бизнесу еще до того, как они вступят в фазу реализации."
                    },
                    {
                        num: 3,
                        name: "Реагирование",
                        text: "Мы обеспечиваем не только раннее обнаружение информационных атак, но еще и предлагаем стратегию решения проблем и адекватную реакцию на возникающие риски и угрозы в медиа."
                    },
                    {
                        num: 4,
                        name: "Уникальность",
                        text: "В отражении информационной агрессии нет места шаблонам. Под каждый кейс мы разрабатываем уникальную методологию защиты от информационных угроз."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/communicationsPage/img2Mobile.jpg", // imgMobile
                cards: [
                    {
                        num: 1,
                        name: "Аудит репутации",
                        text: "Основным продуктом аудита репутации является карта рисков и угроз, которая показывает все слабые места в коммуникационной стратегии и позиционировании компании. В карте анализируются и действующие, и потенциальные риски, которые могут возникнуть из-за изменений внутри компании или во внешней среде."
                    },
                    {
                        num: 2,
                        name: "Противо-действие",
                        text: "На основе карты рисков и угроз разрабатывается система реагирования на информационную агрессию. Заранее готовятся для размещения материалы, формируются сценарии реагирования, разрабатывается стратегия коммуникационного ответа и создаются соответствующие материалы, которые затем размещаются в СМИ, социальных сетях и мессенджерах."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Стратегия и позициони-рование",
                        text: "Выработка эффективной стратегии позиционирования в информационном пространстве, которая обеспечит наиболее выгодные позиции для защиты от информационных рисков и угроз."
                    },
                    {
                        num: 2,
                        name: "Создание смыслов",
                        text: "Работа с целевой аудиторией. Создание смыслов и ассоциаций для формирования желаемого общественного мнения."
                    },
                    {
                        num: 3,
                        name: "Проактивная защита",
                        text: "Прогнозирование потенциальных рисков.Подготовка эффективных коммуникационных позиций, которые позволят смягчить первый и самый болезненный удар."
                    },
                    {
                        num: 4,
                        name: "Управление повесткой",
                        text: "Мы помогаем компаниям становиться центрами экспертизы, источниками повестки и мнений, а не плыть в русле текущего дискурса. Наши спикеры становятся проводниками уникальных знаний и центром притяжения целевой аудитории."
                    }
                ]
            }
        }
    },
    analytics: {
        pageName: "Аналитика",
        subMenuList: [
            {
                num: 1,
                label: "Аналитика",
                anchor: "AnalyticsMain"
            },
            {
                num: 2,
                label: "Разведка по открытым источникам",
                anchor: "AnalyticsScouting"
            },
            {
                num: 3,
                label: "Исследования",
                anchor: "AnalyticsResearch"
            },
            { label: "Отправить запрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/analyticsPage/img1.jpg",
            img1Mobile: "./img/analyticsPage/img1Mobile.jpg",
            img2Mobile: "./img/analyticsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Аналитика",
                textBlock1:
                    "Причина большинства финансовых потерь бизнеса – неверные решения, принятые из-за дефицита информации и неопределенности. Наша компания предоставляет разведывательную и аналитическую поддержку бизнесу. Эти услуги будут особенно полезны в сложных и неоднозначных реалиях современного международного бизнеса.",
                textBlock2:
                    "Наша миссия — обеспечить достаточное количество информации для принятия управленческого решения любой сложности даже в самых сложных и неоднозначных условиях. Наш департамент аналитики и исследований организован по принципу «think tank» («фабрика мысли»), что позволяет эффективно решать любые информационные проблемы клиента.",
                anchor: "AnalyticsMain"
            },
            scouting: {
                num: 2,
                name: "Разведка по открытым источникам",
                textBlock1:
                    "Open source intelligence – разведка по открытым источникам – или OSINT обеспечивает бизнес информацией даже в условиях закрытости и недостатка источников данных. OSINT работает по принципу кейс-стади: на основе информации из открытых источников формируется полная и достоверная картина событий по любому, даже самому сложному вопросу.",
                textBlock2:
                    "Наши аналитики владеют универсальной технологией работы с информацией, что позволяет собирать данные и прояснять ситуацию по самым сложным случаям.Проводится анализ информационного поля для понимания конкретной ситуации и формирования достоверного прогноза ее развития.",

                anchor: "AnalyticsScouting"
            },
            research: {
                num: 3,
                name: "Исследования",
                textBlock2:
                    "Наша команда проводит не только кабинетные,но и полевые исследования. Мы проводим количественные и качественные исследования в любых регионах страны как в сфере экономики и маркетинга,так и в социально-политической плоскости.",

                anchor: "AnalyticsResearch"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Источники",
                        text: "За более чем 15 лет работы мы накопили большое количество источников информации:мы подписаны на все крупнейшие базы данных и информационные источники."
                    },
                    {
                        num: 2,
                        name: "Уникальные навыки",
                        text: "Наши аналитики проходят специальное обучение по программам Андрея Масаловича, поэтому мы можем добывать уникальную информацию из открытых источников."
                    },
                    {
                        num: 3,
                        name: "Экспертиза",
                        text: "Мы сотрудничаем более чем с двумя сотнями экспертов в различных областях знаний, от связей с общественностью, юриспруденции и экономики до робототехники и космической авиации. Наши клиенты могут быть уверены, что получаемая ими информация подтверждена экспертами самого высокого уровня."
                    },
                    {
                        num: 4,
                        name: "Команда",
                        text: "Мы собрали команду уникальных специалистов-политтехнологов, пиарщиков, журналистов и аналитиков. Каждый из них не только прошел свой путь профессионального становления, но и получил специальные знания внутри компании. Синтез опыта и уникальных навыков позволяет нам браться за задачи любой сложности."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/analyticsPage/img2Mobile.jpg", // imgMobile
                cards: [
                    {
                        num: 1,
                        name: "Качественные исследования",
                        text: "Мы специализируемся на проведении фокус-групп и проведении экспертных интервью. Это позволяет нашим клиентам формировать эффективную коммуникационную и маркетинговую политику, а также своевременно ее корректировать."
                    },
                    {
                        num: 2,
                        name: "Количественные исследования",
                        text: "На основе карты рисков и угроз разрабатывается система реагирования на информационную агрессию. Заранее формируются сценарии реагирования, разрабатывается стратегия коммуникационного ответа."
                    }
                ]
            }
        }
    },
    safety: {
        pageName: "Безопасность",
        subMenuList: [
            {
                num: 1,
                label: "Безопасность",
                anchor: "SafetyMain"
            },
            {
                num: 2,
                label: "Экономическая безопасность",
                anchor: "SafetyEconomic"
            },
            // {
            //     num: 3,
            //     label: "Правовая поддержка",
            //     anchor: "SafetySupport"
            // },
            {
                num: 3,
                label: "Кибербезопасность",
                anchor: "SafetyCyber"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/safetyPage/img1.jpg",
            img1Mobile: "./img/safetyPage/img1Mobile.jpg",
            img2Mobile: "./img/safetyPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Безопасность",
                textBlock1:
                    "Причина большинства финансовых потерь бизнеса – неверные решения, принятые из-за дефицита информации и неопределенности. Мы предоставляем разведывательную и аналитическую поддержку бизнесу. Эти услуги будут особенно полезны в сложных и неоднозначных реалиях современного международного бизнеса.",
                textBlock2:
                    "Миссия компании — обеспечить достаточное количество информации для принятия управленческого решения любой сложности даже в самых сложных и неоднозначных условиях. Наш департамент аналитики и исследований организован по принципу «think tank» («фабрика мысли»), что позволяет эффективно решать любые информационные проблемы клиента.",
                anchor: "SafetyMain"
            },
            economic: {
                num: 2,
                name: "Экономическая безопасность",
                textBlock2:
                    "При условии соблюдения законодательства Российской Федерации наша компания обеспечивает экономическую безопасность компании за счет проведения комплексного due diligence в отношении сделок, партнеров, сотрудников и кандидатов компании.",
                anchor: "SafetyEconomic"
            },
            cyber: {
                num: 3,
                name: "Кибербезопасность",
                textBlock2:
                    "Более 15 лет мы разрабатываем и реализовываем решения, обеспечивающие безопасность в федеральных органах государственной власти, госкорпорациях, крупных коммерческих компаниях, а также в банковском секторе.",

                anchor: "SafetyCyber"
            }
            // support: {
            //     num: 3,
            //     name: "Правовая поддержка",
            //     textBlock2:
            //         "Надежная правовая поддержка по широкому кругу экономических вопросов, начиная от корпоративных конфликтов, заканчивая вопросами банкротства и арбитражного управления. Это позволяет не только обеспечивать клиентов информацией для принятия грамотных управленческих решений, но и защищать их интересы в спорах и судебных делах на всех этапах.",
            //     anchor: "SafetySupport"
            // }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Проверка контрагентов",
                        text: "Исчерпывающий объем информации в отношении вероятных контрагентов компании для принятия оптимального решения. Работа осуществляется как по российским, так и по иностранным контрагентам. Включая информацию из социальных сетей и многочисленных иных источников информации."
                    },
                    {
                        num: 2,
                        name: "Проверка соискателей",
                        text: "Проверяется репутация потенциальных соискателей, определяются угрозы и риски, которые могут исходить от потенциального сотрудника."
                    },
                    {
                        num: 3,
                        name: "Проактивная защита",
                        text: "Среди аналитиков компании работают бывшие сотрудники сил правопорядка и специальных структур, которые имеют обширный опыт расследования экономических преступлений и иных противоправных действий."
                    },
                    {
                        num: 4,
                        name: "Исследования",
                        text: "Решение практически любого вопроса: от поиска активов за рубежом, до анализа деятельности оффшорных компаний. Мы знаем, где найти информацию о китайской компании, о последней активности бизнесмена из Латинской Америки, или оценить риски выхода предприятия на рынок Юго-Восточной Азии."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Поиск уязвимостей",
                        text: "Уникальные исследования на собственном программном обеспечении, которое выявляет уязвимости информационных ресурсов, сайтов, приложений. Не только поиск проблем, но и разработка решений для их ликвидации."
                    },
                    {
                        num: 2,
                        name: "Разработка ПО",
                        text: "Разработка специальных программных решений для обеспечения безопасности инфраструктуры."
                    },
                    {
                        num: 3,
                        name: "Разработка аналитических сервисов",
                        text: "Разработка инструментов для глубокого анализа социальных сетей: постов, динамики и активности аудитории. Анализ траффика сайтов и поискового траффика."
                    },
                    {
                        num: 4,
                        name: "Обработка данных",
                        text: "Сбор и обработка больших массивов данных (Big Data), а также разработка собственных решений для оптимизации работы с большими объемами информации."
                    }
                ]
            }
            // block3: {
            //     imageUrl: "../img/safetyPage/img2Mobile.jpg", //imgMobile
            //     cards: [
            //         {
            //             num: 1,
            //             name: "Защита деловой репутации",
            //             text: "ООО «ЛАВИНА ПУЛЬС» помогут восстановить деловую репутацию в случае клеветы в средствах массовой информации, удалить или заблокировать противоправную информацию, а также оказать содействие в защите интересов клиента в случае информационной атаки."
            //         },
            //         {
            //             num: 2,
            //             name: "Корпоративные споры",
            //             text: "Мы не только предоставим самую актуальную информацию о ситуации, связанной с корпоративным спором, но и сможем оказать консультационную юридическую поддержку, а также, в случае необходимости, представить интересы заказчика в судах Российской Федерации."
            //         }
            //     ]
            // }
        }
    },
    learning: {
        pageName: "Курсы",
        subMenuList: [
            {
                num: 1,
                label: "Конкурентная разведка",
                anchor: "LearningService"
            },
            {
                num: 2,
                label: "Корпоративные тренинги",
                anchor: "LearningCorporate"
            },
            { label: "Отправить запрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/learningPage/img1.jpg",
            img1Mobile: "./img/learningPage/img1Mobile.jpg"
        },
        blocks: {
            service: {
                num: 1,
                name: "Конкурентная разведка",
                textBlock1:
                    "В современном мире информация является ключевым ресурсом для успешного развития любой компании. Разведка по открытым источникам (Open Source Intelligence, OSINT) играет важнейшую роль в сборе и анализе разнообразных данных. Благодаря доступности открытой информации, разведка по открытым источникаи облегчает процесс анализа данных и прогнозирования развития ситуации.",
                textBlock2:
                    "Мы расскажем о том, как OSINT может помочь Вашему бизнесу максимально эффективно использовать информацию, улучшить принятие стратегических решений и сохранить конфиденциальность, а также обнаруживать угрозы и риски, связанные с деятельностью конкурентов. Наши методики позволят Вам оперативно выявлять угрозы и возможности для Вашей компании, укреплять позиции на рынке и достигать новых вершин. ",
                linkName1: "",
                linkName2: "",
                anchor: "LearningService"
            },
            corporate: {
                num: 2,
                name: "Корпоративные тренинги",
                textBlock1:
                    "Качественное развитие сотрудников – один из ключевых факторов успеха любого предприятия. Именно поэтому корпоративные тренинги являются неотъемлемой частью эффективной бизнес-стратегии. ",
                textBlock2:
                    "Наши тренинги помогут получить прикладные знания в области OSINT и обеспечить лидерство в информационной среде для непрерывного развития бизнеса.",
                linkName1: "",
                linkName2: "",
                anchor: "LearningCorporate"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Базовый уровень",
                        text: "Основы разведки по открытым источникам (OSINT) и поиск в Интернете."
                    },
                    {
                        num: 2,
                        name: "Средний уровень",
                        text: "Эффективные инструменты OSINT для работы в Интернете и соцсетях, обеспечение собственной безопасности."
                    },
                    {
                        num: 3,
                        name: "Высокий уровень",
                        text: "Поиск в IoT, работа с WireShark, выявление Honeypot и другие инструменты продвинутого OSINT."
                    }
                ]
            }
        }
    },
    audience: {
        pageName: "Анализ аудитории",
        subMenuList: [
            {
                num: 1,
                label: "Сбор статистики",
                anchor: "AudienceStatistic"
            },
            {
                num: 2,
                label: "Инструменты",
                anchor: "AudienceTools"
            },
            {
                num: 3,
                label: "Возможности",
                anchor: "AudiencePossible"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/audiencePage/img1.jpg",
            img1Mobile: "./img/audiencePage/img1Mobile.jpg"
        },
        blocks: {
            helper: {
                num: 1,
                name: "Сбор статистики",
                textBlock1:
                    "Avalanche помогает быстро собирать и обрабатывать большие объемы данных из социальных медиа. Незаменимый инструмент для специалистов самого разного профиля — от PR и маркетинга до корпоративной разведки. Анализ целевой аудитории, прирост подписчиков, их активность и многое другое.",
                textBlock2:
                    "Осуществляется анализ открытых данных в социальных медиа. Позволяет выявлять информационные волны и атаки фейковых аккаунтов (ботнетов). Обеспечивается оперативность получения данных, прозрачность и объективность информации.",
                anchor: "AudienceStatistic"
            },
            tools: {
                num: 2,
                name: "Инструменты",
                textBlock1: "",
                textBlock2: "",
                anchor: "AudienceTools"
            },
            possibilities: {
                num: 3,
                name: "Возможности",
                textBlock1:
                    "Пользователь сообщает системе, какие группы и страницы интересуют, и после периода сбора и обработки данных, получает первые результаты. Анализ данных осуществляется за последний год. Система обрабатывает несколько ключевых параметров: количество пользователей, постов, лайков, комментариев и просмотров.",
                textBlock2:
                    "Система агрегирует иформацию и выводит ее в удобном интерактивном виде.\n\n Чтобы узнать о поддерживаемых сетях на текущий момент, напишите нам.",
                linkName1: "",
                linkName2: "",
                anchor: "AudiencePossible"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "Скрытое наблюдение за активностью конкретных источников угроз."
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Анализ интереса и оценка реакции целевой аудитории в глобальных сетях к тем или иным проблемам и темам."
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Анализ социальной напряженности."
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Контроль за активностью сообществ в социальных медиа."
                    }
                ]
            }
        }
    },
    scanner: {
        pageName: "Сканер уязвимостей",
        subMenuList: [
            {
                num: 1,
                label: "Современные угрозы",
                anchor: "ScannerPageDanger"
            },
            {
                num: 2,
                label: "Контроль защищенности",
                anchor: "ScannerPageControl"
            },
            {
                num: 3,
                label: "Возможности",
                anchor: "ScannerPagePossibilities"
            },
            {
                num: 4,
                label: "Преимущества",
                anchor: "ScannerPageBenifits"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/scannerPage/img1.jpg",
            img1Mobile: "./img/scannerPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Современные угрозы",
                textBlock1:
                    "Сегодня веб-ресурсы являются неотъемлемыми элементами бизнес-процессов. Многочисленные исследования показывают, что более 40% ресурсов содержат критические уязвимости.Проблемы в безопасности веб-ресурсов – это проблемы в бизнес-процессах организации. Они могут привести к:",
                textBlock2:
                    "• разглашению конфиденциальной информации;\n• снижению прибыли из-за нарушения бизнес-процессов;\n• негативному влиянию на репутацию компании;\n• получению несанкционированного доступа к личным данным клиентов;\n• проникновению нарушителей во внутренние корпоративные информационные системы.",
                anchor: "ScannerPageDanger"
            },
            control: {
                num: 2,
                name: "Контроль защищенности",
                textBlock1:
                    "Современные злоумышленники обнаруживают и эксплуатируют все новые уязвимости на веб-ресурсах, которые содержат чувствительные данные. Ошибки программной реализации, несвоевременное обновление программного обеспечения, устаревшие пароли и многое другое, все это приводит к появлению серьезных уязвимостей в интернет-проектах.",
                textBlock2:
                    "Веб-сканер - это набор решений, направленных на обеспечение функций контроля защищенности информационной системы, построенной на базе веб-технологий. Веб-сканер позволяет реализовать функции обнаружения, предотвращения и реагирования на попытки несанкционированной активности удаленных и внутренних пользователей компьютерных сетей.",
                anchor: "ScannerPageControl"
            },
            possibilities: {
                num: 3,
                name: "Возможности",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPagePossibilities"
            },
            unnamed: {
                textBlock:
                    "Способы контроля защищенности сайтов учитывают современные угрозы, бизнес-риски компании и требования регуляторов."
            },
            benifits: {
                num: 4,
                name: "Преимущества",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPageBenifits"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: "Уязвимости архитектуры",
                    text: "К данному типу относятся уязвимости, допущенные при создании веб-приложений. Проводится комплексная проверка на предмет внедрения вредоносного кода извне, получения НСД и прочее."
                },
                {
                    num: 2,
                    name: "Уязвимости файлов и директорий",
                    text: "Система проверяется на наличие открытых для публичного доступа файлов и папок, в том числе без прямых ссылок."
                },
                {
                    num: 3,
                    name: "Сканеры конфигураций",
                    text: "Анализируется конфигурация информационной системы. Определяется наличие ошибок/слабых мест в настройке программно-аппаратного комплекса (DNS, конфигурация веб-сервера и пр.)."
                },
                {
                    num: 4,
                    name: "Ошибки администрирования",
                    text: "В процессе установки и эксплуатирования веб-приложения допускается множество типичных ошибок, которые могут иметь весьма печальные последствия. Использование типовых паролей, наличие сторонних средств администрирования, а также присутствие стороннего исходного кода."
                },
                {
                    num: 5,
                    name: "Негативные социальные явления",
                    text: "Поиск различного рода негативной информации о веб-сайте: публикации на форумах безопасности об обнаруженной уязвимости, просьбы о взломе веб-сайта или DoS-атаке и прочее."
                },
                {
                    num: 6,
                    name: "Генерация отчетов",
                    text: "Результаты работы сканеров в виде единого, наглядного отчёта."
                }
            ],
            block1Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "Уязвимости архитектуры",
                        text: "К данному типу относятся уязвимости, допущенные при создании веб-приложений. Проводится комплексная проверка на предмет внедрения вредоносного кода извне, получения НСД и прочее."
                    },
                    {
                        num: 2,
                        name: "Уязвимости файлов и директорий",
                        text: "Система проверяется на наличие открытых для публичного доступа файлов и папок, в том числе без прямых ссылок."
                    },
                    {
                        num: 3,
                        name: "Сканеры конфигураций",
                        text: "Анализируется конфигурация информационной системы. Определяется наличие ошибок/слабых мест в настройке программно-аппаратного комплекса (DNS, конфигурация веб-сервера и пр.)."
                    },
                    {
                        num: 4,
                        name: "Ошибки администрирования",
                        text: "В процессе установки и эксплуатирования веб-приложения допускается множество типичных ошибок, которые могут иметь весьма печальные последствия. Использование типовых паролей, наличие сторонних средств администрирования, а также присутствие стороннего исходного кода."
                    },
                    {
                        num: 5,
                        name: "Негативные социальные явления",
                        text: "Поиск различного рода негативной информации о веб-сайте: публикации на форумах безопасности об обнаруженной уязвимости, просьбы о взломе веб-сайта или DoS-атаке и прочее."
                    },
                    {
                        num: 6,
                        name: "Генерация отчетов",
                        text: "Результаты работы сканеров в виде единого, наглядного отчёта."
                    }
                ]
            },
            block2: [
                {
                    num: 1,
                    name: "",
                    text: "Постоянный контроль уровня защищенности сайтов организации в режиме «24/7/365»",
                    imgLink: "./img/scannerPage/small1.jpg"
                },
                {
                    num: 2,
                    name: "",
                    text: "Уведомление ответственных лиц в случае обнаружения критических уязвимостей",
                    imgLink: "./img/scannerPage/small2.jpg"
                },
                {
                    num: 3,
                    name: "",
                    text: "Регулярные еженедельные отчеты о текущем уровне защищенности",
                    imgLink: "./img/scannerPage/small3.jpg"
                },
                {
                    num: 4,
                    name: "",
                    text: "Рекомендации по устранению обнаруженных уязвимостей и помощь в их реализации",
                    imgLink: "./img/scannerPage/small4.jpg"
                },
                {
                    num: 5,
                    name: "",
                    text: "Снижение затрат на реализацию требований информационной безопасности",
                    imgLink: "./img/scannerPage/small5.jpg"
                },
                {
                    num: 6,
                    name: "",
                    text: "Не оказывает дополнительной нагрузки на вычислительные ресурсы организации",
                    imgLink: "./img/scannerPage/small6.jpg"
                }
            ],
            block2Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "Постоянный контроль уровня защищенности сайтов организации в режиме «24/7/365»",
                        imgLink: "./img/scannerPage/small1.jpg"
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Уведомление ответственных лиц в случае обнаружения критических уязвимостей",
                        imgLink: "./img/scannerPage/small2.jpg"
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Регулярные еженедельные отчеты о текущем уровне защищенности",
                        imgLink: "./img/scannerPage/small3.jpg"
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Рекомендации по устранению обнаруженных уязвимостей и помощь в их реализации",
                        imgLink: "./img/scannerPage/small4.jpg"
                    },
                    {
                        num: 5,
                        name: "",
                        text: "Снижение затрат на реализацию требований информационной безопасности",
                        imgLink: "./img/scannerPage/small5.jpg"
                    },
                    {
                        num: 6,
                        name: "",
                        text: "Не оказывает дополнительной нагрузки на вычислительные ресурсы организации",
                        imgLink: "./img/scannerPage/small6.jpg"
                    }
                ]
            }
        }
    },
    aboutUs: {
        pageName: "О нас",
        subMenuList: [
            {
                num: 1,
                label: "Андрей Игоревич Масалович",
                anchor: "AboutUsPageMain"
            },
            {
                num: 2,
                label: "Наша миссия",
                anchor: "AboutUsPageMission"
            },
            {
                num: 3,
                label: "Направления деятельности",
                anchor: "AboutUsPageDirection"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/aboutUsPage/img1.jpg",
            img1Mobile: "./img/aboutUsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Андрей Игоревич Масалович",
                textBlock1:
                    "Впервые продукт под названием Avalanche появился на российском рынке в 2003 году. Его создатель – первопроходец на рынке конкурентной разведки, подполковник ФАПСИ в отставке, Андрей Масалович. На протяжении почти 20 лет система использовалась для контроля общественных настроений и борьбы с террористической угрозой силовыми ведомствами, федеральными и региональными властями.",
                textBlock2:
                    "Сегодня, аналитические системы, построенные на базе технологической платформы Avalanche активно используются коммерческими компаниями для мониторинга российских и иностранных рынков, партнеров и конкурентов.",
                linkName1: "",
                linkName2: "",
                anchor: "AboutUsPageMain"
            },
            education: {
                name: "Образование",
                textBlock1: "",
                textBlock2:
                    "2005 – курс “Corporate Governance”, York University (Торонто, Канада)\n1994 – курс “Conversion & Privatization”, University of Los-Angeles (США)\n1992 – спецкурс King’s School of English, (Лондон, Великобритания)\n1989 – аспирантура МАИ, кандидат физико-математических наук\n1984 – Московский авиационный институт, ф-т «Прикладная математика»"
            },
            expert: {
                name: "Эксперт по конкурентной разведке",
                textBlock1: "",
                textBlock2:
                    "Президент Консорциума «Инфорус»\nКандидат физико-математических наук\nЛауреат стипендии РАН «Выдающийся ученый России» – 1993\nПремия IPCC «Лучшая работа в компьютерной журналистике России» -1995\nПремия «Топ-профи» (МКК, категории «Бизнес» и «Персона») – 2002"
            },
            unnamed: {
                name: "",
                textBlock1:
                    "Автор более 100 печатных работ, основные результаты работ запатентованы в США. Неоднократно публиковался в журналах «Вопросы Радиоэлектроники», PC Week, Мир ПК, «Интеркомпьютер», «Софтмаркет», «Рынок ценных бумаг» и других. В 1995 году за цикл статей по нечеткой логике получил премию международной ассоциации журналистов IPCC «Лучшая работа в компьютерной журналистике России». Участник и член Оргкомитета многих международных конференций. В 1995 году получил благодарность EuroPar Steering Committee за личный вклад в организацию конференции EuroPar-95 (Стокгольм). Участник и руководитель более 20 успешных международных проектов. С 1998 года организовал разработку комплекса аналитических программ для торговых компаний и холдингов. Программа «Управление супермаркетом» стала победителем конкурса «Бизнес-софт’98». В 2000 году создал компанию «ИнтерРусСофт» для выполнения заказных интернет-проектов на мировом рынке.",
                textBlock2:
                    "Осуществил ряд успешных Интернет-проектов,в том числе www.trubka.ru, www.rusbiotech.ru, www.procurezone.com. Проекты получили несколько наград Интернет-конкурсов: «Признание жюри» (Интернить-2001), диплом «Золотой сайт - 2001» и др.В 2001 году стал президентом Консорциума Инфорус, объединившего более 80 ведущих ИТ-компаний России. Эксперт РФФИ (1995-2000), INTAS (1998) МТЦ ООН (с 2002г), АТЭС (с 2003г.). Член жюри конкурсов BIT (c 2004 г.) и «Премия Рунета» (с 2005 г.).Разработчик и главный конструктор технологии для анализа больших данных (Big Data) – Avalanche.\n\nТехнологии анализа больших данных имеют большую актуальность, как в сфере бизнеса, так и в государственном секторе."
            },
            mission: {
                num: 2,
                name: "Наша миссия",
                textBlock1: "",
                textBlock2:
                    "История компании восходит к 2003 году, когда был основан Консорциум Инфорус и создана первая версия системы мониторинга Avalanche. Уже более 20 лет наша экспертиза и наработки помогают российским компаниям и органам государственной власти успешно отражать коммуникационные и информационные угрозы. На нашем счету множество крупных IT-проектов и опыт информационно-аналитического обеспечения федеральных и региональных органов государственной власти и их первых лиц, госкорпораций и коммерческих компаний, российском ВПК.",
                anchor: "AboutUsPageMission"
            },
            direction: {
                num: 3,
                name: "Направления деятельности",
                textBlock1: "",
                textBlock2:
                    "Наша команда специализируется на обеспечении комплексной безопасности на основе сбора и анализа данных открытых источников, включая коммуникации, экономическую и информационную безопасность.\n\nТакже, одним из основных направлений деятельности организации является разработка корпоративного программного обеспечения.",
                anchor: "AboutUsPageDirection"
            }
        }
    },
    demonstration: {
        pageName: "Демонстрация",
        subMenuList: [
            {
                num: 1,
                label: "Демонстрация",
                anchor: "DemonstrationPageMain"
            },
            {
                num: 2,
                label: "Публичные проекты",
                anchor: "DemonstrationPublicProjects"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/demonstrationPage/img1.jpg"
        },
        blocks: {
            PublicProjects: {
                num: 2,
                name: "Публичные проекты",
                textBlock1:
                    "Для демонстрации работы Avalanche мы преднастроили несколько публичных проектов. Система агрегирует информацию из тысяч открытых источников, фильтрует ее по различным тематикам и представляет в виде лент новостей. Это бесплатно.",
                textBlock2:
                    "На основе обработанной информации, система анализирует интерес целевой аудитории в глобальных сетях к тем или иным проблемам и предупреждает о формирующихся угрозах и рисках.",
                linkName1: "",
                linkName2: "",
                anchor: "DemonstrationPublicProjects"
            },
            iframe: {
                textBlock:
                    "Экран руководителя (режим по умолчанию). 8 главных тем для мониторинга информации по тематике Covid 19. Контроль оперативной обстановки в сети Интернет по федеральным округам Российской Федерации.",

                anchor: "DemonstrationPublicProjects"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: "Политические лидеры",
                    text: "Упоминания ключевых мировых политических лидеров в иностранных СМИ.",
                    linkName: "Перейти",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Министерства",
                    text: "Повестка российских министерств и ведомств в отечественных СМИ.",
                    linkName: "Перейти",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Упоминания коронавирусной инфекции в региональных СМИ России, разбитые на федеральные округа.",
                    linkName: "Перейти",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Поиск авиабилетов",
                    text: "Сбор информации о дешевых перелетах и акциях различных мировых авиакомпаний.",
                    linkName: "Перейти",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Новости мобилизации",
                    text: "Все самое важное о мобилизационной кампании и волонтерских миссиях.",
                    linkName: "Перейти",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersec",
                    text: "Новости из мира разведки и кибербезопасности, мониторинг утечек и поиск уязвимостей.",
                    linkName: "Перейти",
                    externalLink: "https://osint.avl.team/"
                }
            ]
        }
    },
    contacts: {
        pageName: "Контакты",
        subMenuList: "",
        blocks: {
            contacts: {
                num: "",
                name: "Как нас найти:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span>
                                г.Москва ,ул.2-я Останкинская ,д.6 , к.103
                            </span>
                        </div>
                        {/* <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span>+7 (499) 393-00-15</span>
                        </div> */}
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span>По общим вопросам: info@avl.team</span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span>Отдел продаж: sales@avl.team</span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span>Служба поддержки support@avl.team</span>
                        </div>
                    </>
                )
            },
            contactsTablet: {
                num: "",
                name: "Как нас найти:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span className="text-right pl-15">
                                г.Москва ,ул.2-я Останкинская ,д.6 , к.103
                            </span>
                        </div>
                        {/* <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span className="text-right">
                                +7 (499) 393-00-15
                            </span>
                        </div> */}
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span className="text-right">
                                По общим вопросам: info@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span className="text-right">
                                Отдел продаж: sales@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span className="text-right">
                                Служба поддержки support@avl.team
                            </span>
                        </div>
                    </>
                )
            }
        }
    },
    cookies: {
        pageName: "Как работают файлы cookie",
        subMenuList: [
            {
                num: 1,
                label: "Политика использования файлов cookie",
                anchor: "CookiesPagePolitics"
            },
            {
                num: 2,
                label: "Что такое файлы cookie?",
                anchor: "CookiesPageWhatItIs"
            },
            {
                num: 3,
                label: "Как мы используем файлы cookie?",
                anchor: "CookiesPageWhatWeUse"
            },
            {
                num: 4,
                label: "Какие типы файлов cookie мы используем?",
                anchor: "CookiesPageWhatTypes"
            },
            {
                num: 4,
                label: "Я могу изменить настройки файлов cookie?",
                anchor: "CookiesPageCanChange"
            }
        ],
        imageUrls: {
            img1: "./img/cookiesPage/img1.jpg",
            img1Mobile: "./img/cookiesPage/img1Mobile.jpg"
        },
        blocks: {
            politics: {
                num: 1,
                name: "Политика использования файлов cookie",
                textBlock1:
                    "В настоящей Политике использования файлов cookie объясняется, что такое файлы cookie, как и какие типы файлов cookie мы используем, а также как управлять настройками файлов cookie. Для получения дополнительной информации о том, как мы используем, храним и защищаем Ваши личные данные, ознакомьтесь с нашей Политикой конфиденциальности.",
                textBlock2:
                    "Вы можете изменить или отозвать свое согласие с использованием файлов cookie на нашем веб-сайте в любое время.",
                anchor: "CookiesPagePolitics"
            },
            whatItIs: {
                num: 2,
                name: "Что такое файлы cookie?",
                textBlock2:
                    "Файлы cookie — это небольшие текстовые файлы, которые используются для хранения небольших фрагментов информации. Они сохраняются на Вашем устройстве, когда веб-сайт загружается в Ваш браузер. Файлы cookie помогают нам обеспечить правильную работу веб-сайта, сделать его более безопасным, улучшить взаимодействие с пользователем, понять, как работает веб-сайт,и проанализировать, что работает не так и где требуется улучшение.",
                anchor: "CookiesPageWhatItIs"
            },
            whatWeUse: {
                num: 3,
                name: "Как мы используем файлы cookie?",
                textBlock2:
                    "Как и большинство онлайн-сервисов, наш веб-сайт использует собственные и сторонние файлы cookie для нескольких целей. Часть из них в основном необходимы для правильной работы веб-сайта, и они не собирают личные данные. Сторонние файлы cookie, используемые на нашем веб-сайте, предназначены для понимания того, как работает веб-сайт, как Вы взаимодействуете с нашим веб-сайтом, для обеспечения безопасности наших услуг, предоставления релевантной рекламы и т.п.",

                anchor: "CookiesPageWhatWeUse"
            },
            whatTypes: {
                num: 4,
                name: "Какие типы файлов cookie мы используем?",

                anchor: "CookiesPageWhatTypes"
            },
            canChange: {
                num: 5,
                name: "Я могу изменить настройки файлов cookie?",
                textBlock1:
                    "Если Вы решите изменить настройки позже, Вы можете щелкнуть вкладку «Политика конфиденциальности и использования файлов cookie» на своем экране. Это снова отобразит уведомление о согласии, позволяя вам изменить свои предпочтения или полностью отозвать свое согласие.",
                textBlock2:
                    "Кроме того, разные браузеры предоставляют разные способы блокировки и удаления файлов cookie, используемых веб-сайтами. Вы можете изменить настройки своего браузера, чтобы заблокировать/удалить файлы cookie.",
                anchor: "CookiesPageCanChange"
            },
            cookies: {
                analytics: {
                    num: "",
                    name: "cookielawinfo-checkbox-analytics",
                    textBlock2:
                        "Этот файл cookie устанавливается плагином GDPR Cookie Consent. Файл cookie используется для хранения согласия пользователя на использование файлов cookie в категории «Аналитика»."
                },
                functional: {
                    num: "",
                    name: "cookielawinfo-checkbox-functional",
                    textBlock2:
                        "Файл cookie устанавливается согласием на использование файлов cookie GDPR для записи согласия пользователя на файлы cookie в категории «Функциональные»."
                },
                necessary: {
                    num: "",
                    name: "cookielawinfo-checkbox-necessary",
                    textBlock2:
                        "Эти файлы cookie устанавливаются плагином GDPR Cookie Consent. Файлы cookie используются для хранения согласия пользователя на использование файлов cookie в категории «Необходимо»."
                },
                others: {
                    num: "",
                    name: "cookielawinfo-checkbox-others",
                    textBlock2:
                        "Этот файл cookie устанавливается плагином GDPR Cookie Consent. Файл cookie используется для хранения согласия пользователя на использование файлов cookie в категории «Другое»."
                },
                performance: {
                    num: "",
                    name: "cookielawinfo-checkbox-performance",
                    textBlock2:
                        "Этот файл cookie устанавливается плагином GDPR Cookie Consent. Файл cookie используется для хранения согласия пользователя на использование файлов cookie в категории «Производительность»."
                },
                policy: {
                    num: "",
                    name: "viewed_cookie_policy",
                    textBlock2:
                        "Файл cookie устанавливается плагином GDPR Cookie Consent и используется для хранения информации о том, дал ли пользователь согласие на использование файлов cookie. Он не хранит никаких личных данных."
                }
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Основные",
                        text: "Некоторые файлы cookie необходимы для того, чтобы Вы могли пользоваться всеми функциями нашего сайта. Они позволяют нам поддерживать пользовательские сеансы и предотвращать любые угрозы безопасности и не собирают и не хранят никакой личной информации."
                    },
                    {
                        num: 2,
                        name: "Статистические",
                        text: "Эти файлы cookie хранят такую ​​информацию, как количество посетителей веб-сайта, количество уникальных посетителей, а также информацию о том, какие страницы веб-сайта были посещены, источник посещения и т. д. Эти данные помогают нам понять и проанализировать, насколько хорошо работает веб-сайт и где он нуждается в улучшении."
                    },
                    {
                        num: 3,
                        name: "Функциональные",
                        text: "Это файлы cookie, которые помогают некоторым несущественным функциям на нашем веб-сайте. Эти функции включают встраивание контента, такого как видео, или обмен контентом веб-сайта на платформах социальных сетей."
                    },
                    {
                        num: 4,
                        name: "Предпочтения",
                        text: "Эти файлы cookie помогают нам сохранять Ваши настройки и предпочтения при просмотре, такие как языковые настройки, чтобы Вам было удобнее при будущих посещениях веб-сайта."
                    }
                ]
            }
        }
    },
    conditions: {
        pageName: "Условия использования",
        subMenuList: "",
        imageUrls: {
            img1: "./img/useConditionsPage/img1.jpg",
            img1Mobile: "./img/useConditionsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "",
                textBlock1:
                    "Сервис и входящие в него компоненты, разрабатываемые ООО «ЛАВИНА ПУЛЬС» предназначены для задач интернет-мониторинга, а также обработки и анализа открытых данных из сети Интернет. Подчеркиваем, мы используем только открытые данные, которые находятся в свободном доступе в сети Интернет.",
                textBlock2: (
                    <div
                        style={{ display: "inline-block", marginBottom: "2px" }}
                    >
                        {`Если Вы используете наш Сервис каким-либо способом, Вы тем самым подтверждаете свое согласие соблюдать следующие правила (все условия, оговоренные далее, относятся как к Сервису в целом, так и ко всем его компонентам в отдельности, но не исключает дополнительных условий`}
                        <Link
                            to="/publicOfertaPage"
                            className="ml-5 "
                        >
                            <span className="bborder">публичной оферты</span>
                        </Link>
                        {` :`}
                    </div>
                ),
                anchor: ""
            },
            support: {
                name: "Условия технической поддержки",
                line1: "В рамках Технической поддержки Правообладатель и/или его Партнеры обязуются оказывать следующие услуги:\n\r\u0020\u0020\u0020\u2022 обновление новых версий как Сервиса в целом, так и всех его компонентов, по мере их появления; \n\r\u0020\u0020\u0020\u2022организация консультаций и устранение инцидентов (по электронной почте – support@avl.team и через специальную форму обратной связи)",
                line2_1:
                    "Временной режим регистрации заявок на обслуживание в системе технической поддержки (и по официальному адресу электронной почты)",
                line2_2: "Круглосуточно (24/7)",
                line3_1: "Временной режим устранения критичных инцидентов",
                line3_2: "С 10:00 до 20:00 часов по московскому времени",
                line4_1:
                    "Временной режим решения прочих заявок и обработки информационных запросов",
                line4_2:
                    "С 10:00 до 20:00 часов по московскому времени (кроме выходных и праздничных дней)",
                line5_1: "Время реакции на заявку",
                line5_2:
                    "4 часа (время реакции на заявку включает в себя только рабочее время)"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "В случае приобретения доступа к Сервису, Вы имеете право пользоваться Сервисом на том количестве компьютеров/мобильных устройств, которое было указано в заказе."
                    },
                    {
                        num: 2,
                        name: "",
                        text: "ООО «ЛАВИНА ПУЛЬС» гарантирует работу Сервиса в соответствии с условиями, описанными в руководстве по эксплуатации."
                    },
                    {
                        num: 3,
                        name: "",
                        text: "ООО «ЛАВИНА ПУЛЬС» не гарантирует работоспособность Сервиса при нарушении условий, описанных в руководстве по эксплуатации, а также в случае нарушения Пользователем условий использования Сервиса."
                    },
                    {
                        num: 4,
                        name: "",
                        text: "ООО «ЛАВИНА ПУЛЬС» не несет ответственности за сбои в работе связанные с использованием Сервиса или невозможностью использования Сервиса в случае несоответствия ЭВМ Пользователя, операционных систем и сопутствующего программного обеспечения системным требованиям."
                    },
                    {
                        num: 5,
                        name: "",
                        text: "ООО «ЛАВИНА ПУЛЬС» не несет ответственности за использование Пользователем Сервиса в злонамеренных целях. При этом ООО «ЛАВИНА ПУЛЬС» оставляет за собой право отслеживать и анализировать активность Пользователя (в обезличенном виде) с целью предотвращения использования Сервиса в противоправных целях, нарушающих законодательство Российской Федерации."
                    },
                    {
                        num: 6,
                        name: "",
                        text: "Пользователь обязуется не использовать Сервис для проверки безопасности сайтов без согласия их владельцев. Пользователь несет ответственность, установленную законодательством Российской Федерации, за совершенные проверки безопасности и все возможные последствия таких проверок."
                    },
                    {
                        num: 7,
                        name: "",
                        text: "Запрещается производить обратную разработку (обратное проектирование, обратный инжиниринг, реверс-инжиниринг), а также декомпиляцию и/или модификацию исходного кода любых наших продуктов."
                    },
                    {
                        num: 8,
                        name: "",
                        text: "За нарушение авторских прав нарушитель несет гражданскую, административную или уголовную ответственность в соответствии с законодательством Российской Федерации."
                    },
                    {
                        num: 9,
                        name: "",
                        text: "После активации учетной записи (за исключением версий, предназначенных для ознакомительных целей) Пользователь приобретает право получать от ООО «ЛАВИНА ПУЛЬС» Техническую поддержку в течение срока действия лицензии."
                    }
                ]
            }
        }
    },
    personalData: {
        pageName: "Соглашение об обработке персональных данных",
        subMenuList: "",
        imageUrls: {
            img1: "./img/personalDataAgreementPage/img1.jpg",
            img1Mobile: "./img/personalDataAgreementPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Соглашение об обработке персональных данных",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    confidentionalPolitic: {
        pageName: "Политика конфиденциальности",
        subMenuList: "",
        imageUrls: {
            img1: "./img/confidentionalPoliticPage/img1.jpg",
            img1Mobile: "./img/confidentionalPoliticPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Политика конфиденциальности в отношении обработки персональных данных",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    publicOferta: {
        pageName: "Публичная оферта",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicOfertaPage/img1.jpg",
            img1Mobile: "./img/publicOfertaPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Соглашение об оказании услуги (публичная оферта)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <p style={{ fontWeight: "bold" }}>
                            "Дата размещения на Сайте «01» января 2023 г.
                        </p>
                        <br />
                        <p>
                            ООО «ЛАВИНА ПУЛЬС» предлагает заключить настоящее
                            Соглашение об оказании Услуги по предоставлению
                            права пользования простой (неисключительной)
                            лицензией на продукт. Предложение адресовано
                            неопределенному кругу дееспособных физических,
                            юридических лиц, индивидуальных предпринимателей и
                            является публичной офертой в соответствии с п. 2 ст.
                            437 ГК РФ.
                        </p>
                    </>
                ),
                anchor: ""
            },

            mainTablet: {
                num: "",
                name: "Соглашение об оказании услуги (публичная оферта)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <p style={{ fontWeight: "bold", width: "20vw" }}>
                            "Дата размещения на Сайте «01» января 2023 г.
                        </p>
                        <br />
                        <p>
                            ООО «ЛАВИНА ПУЛЬС» предлагает заключить настоящее
                            Соглашение об оказании Услуги по предоставлению
                            права пользования простой (неисключительной)
                            лицензией на продукт. Предложение адресовано
                            неопределенному кругу дееспособных физических,
                            юридических лиц, индивидуальных предпринимателей и
                            является публичной офертой в соответствии с п. 2 ст.
                            437 ГК РФ.
                        </p>
                    </>
                ),
                anchor: ""
            }
        }
    },
    tariffs: {
        pageName: "Тарифы",
        subMenuList: [
            {
                num: 1,
                label: "Виртуальный ситуационный центр",
                anchor: "TariffsPageCenter"
            },
            {
                num: 2,
                label: "Лицензии и внедрение",
                anchor: "TariffsPageLicence"
            },
            { label: "Отправить запрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/tariffsPage/img1.jpg",
            img1Mobile: "./img/tariffsPage/img1Mobile.jpg",
            img2: "./img/tariffsPage/img2.jpg",
            img2Mobile: "./img/tariffsPage/img2Mobile.jpg"
        },
        blocks: {
            center: {
                num: 1,
                name: "Виртуальный ситуационный центр",
                textBlock1: "",
                textBlock2:
                    "Сервис мониторинга интернет-пространства обеспечивает Заказчика возможностями современного ситуационного центра в части мониторинга оперативной обстановки и раннего обнаружения интернет-угроз. Для коммерческих Заказчиков предусмотрено 3 тарифных плана.",
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageCenter"
            },
            licence: {
                num: 2,
                name: "Лицензии и внедрение",
                textBlock1:
                    "При внедрении системы на оборудовании Заказчика возможны индивидуальные доработки ПО в соответствии с требованиями Заказчика.",
                textBlock2: (
                    <>
                        <span
                            style={{
                                fontSize: 24 + "px",
                                paddingLeft: 20 + "px",
                                lineHeight: 1.2,
                                position: "relative",
                                top: -5 + "px"
                            }}
                        >
                            Стоимость базового лицензионного пакета без
                            доработок - 15 млн. ₽*
                        </span>
                        <div
                            style={{
                                fontSize: 10 + "px",
                                paddingLeft: 20 + "px",
                                paddingTop: 10 + "px",
                                lineHeight: 1.2,
                                position: "relative",
                                top: -5 + "px"
                            }}
                        >
                            * Для Заказчиков в сфере образовательной
                            деятельности предусмотрена система скидок.
                        </div>
                    </>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            },
            licenceTablet: {
                num: 2,
                name: "Лицензии и внедрение",
                textBlock1:
                    "При внедрении системы на оборудовании Заказчика возможны индивидуальные доработки ПО в соответствии с требованиями Заказчика.",
                textBlock2: (
                    <>
                        <span className="fs-20-20">
                            Стоимость базового лицензионного пакета без
                            доработок - 15 млн. ₽*
                        </span>
                        <div
                            style={{
                                fontSize: 10 + "px",
                                paddingTop: 10 + "px",
                                lineHeight: 1.2,
                                position: "relative",
                                top: -5 + "px"
                            }}
                        >
                            * Для Заказчиков в сфере образовательной
                            деятельности предусмотрена система скидок.
                        </div>
                    </>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Авторская разработка",
                        text: "В основе комплекса - оригинальная отечественная технология интернет-мониторинга и построения распределенных баз данных."
                    },
                    {
                        num: 2,
                        name: "Глубокий анализ данных",
                        text: "Расширенный набор современных алгоритмов для обработки больших данных (т.н. Big Data), включая экспресс-анализ, прогнозирование, моделирование и многое другое."
                    },
                    {
                        num: 3,
                        name: "Гибкость и адаптивность",
                        text: "Поддерживает интеграцию с государственными, ведомственными и специализированными базами данных. Возможность интеграции внешних источников данных."
                    },
                    {
                        num: 4,
                        name: "Открытый исходный код",
                        text: "Все компоненты комплекса разработаны в России и основаны на программных продуктах с открытым кодом."
                    }
                ]
            }
        },
        table: {
            top: {
                column1: {
                    name: "Стандарт",
                    price: "от 150 тыс. ₽ / месяц"
                },
                column2: {
                    name: "Оптимум",
                    price: "от 300 тыс. ₽ / месяц"
                },
                column3: {
                    name: "Премиум",
                    price: "от 500 тыс. ₽ / месяц"
                }
            },
            info: [
                {
                    name: "Проект в системе Avalanche",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Приватный канал в Телеграме",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Ежемесячная аналитическая справка",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Аналитическая поддержка",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Еженедельные аналитические справки",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Еженедельные новостные подборки",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Аналитические справки ",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "(до 3-х)",
                    premiumValueExtrainfo: "(до 5-и)"
                },
                {
                    name: "Приватный канал в Телеграме с онлайн-трансляцией ключевых новостей",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Ежедневные информационные справки",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Мониторинг активности конкурентов\n\r(не более 5-и компаний)",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Разработка и обновление карты рисков и угроз",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Поиск репутационных уязвимостей",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Дополнительные работы по согласованию",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                }
            ]
        }
    },
    question: {
        pageName: "Связаться с нами", // поставил Связаться с нами
        subMenuList: ""
    },
    request: {
        pageName: "Отправить запрос",
        subMenuList: ""
    },
    publications: {
        pageName: "Публикации",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicationsPage/img1.jpg",
            img1Mobile: "./img/publicationsPage/img1Mobile.jpg"
        }
    },
    publicActivity: {
        pageName: "Публичная деятельность",
        subMenuList: [
            {
                num: 1,
                label: "Публикации",
                anchor: "publicActivityPageMain"
            },
            {
                num: 2,
                label: "Книга «КиберДед знает»",
                anchor: "publicActivityPageBook"
            },
            {
                num: 3,
                label: "Телеграм-каналы",
                anchor: "publicActivityPageTG"
            },
            {
                num: 4,
                label: "Социальные сети",
                anchor: "publicActivityPageSocial"
            },
            { label: "Задать вопрос", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/publicActivityPage/img1.jpg",
            img1Mobile: "./img/publicActivityPage/img1Mobile.jpg",
            img2Mobile: "./img/publicActivityPage/img2Mobile.jpg",
            middle1: "./img/publicActivityPage/middle1.jpg",
            middle2: "./img/publicActivityPage/middle2.jpg",
            middle3: "./img/publicActivityPage/middle3.jpg"
        },
        blocks: {
            publications: {
                num: 1,
                name: "Публикации",
                textBlock1: "",
                textBlock2: "",
                linkName1: "Смотреть все",
                link1: "/publicationsPage",
                linkName2: "",
                anchor: "publicActivityPageMain"
            },
            cyberDad: {
                num: 2,
                name: `Книга\r\n«КиберДед знает»`,
                textBlock1: "",
                textBlock1Img: "./img/publicActivityPage/small1.jpg",
                imgLink: "./img/publicActivityPage/small1.jpg",
                textBlock2:
                    "Андрей Масалович, предприниматель, интернет-разведчик и автор YouTube-канала «КиберДед», убежден: любой провал — это всегда космический пинок для поиска возможностей. Главное — перестать тянуть кота за хвост и наматывать сопли на кулак. Чем ниже точка, с которой ты стартуешь, тем сокрушительнее будет победа. Прошедший девяностые и нулевые, заработавший реальные деньги на кризисах, автор научит вас правилам антихрупкости в российской действительности.",
                linkName1: "Купить",
                link1: "https://eksmo.ru/book/belyy-pisets-10-sovetov-ot-kiberdeda-kak-priyti-k-uspekhu-v-rossii-ITD1193720/",
                linkName2: "",
                anchor: "publicActivityPageBook"
            },
            tg: {
                num: 3,
                name: "Телеграм-каналы",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageTG"
            },
            social: {
                num: 4,
                name: "Социальные сети",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageSocial"
            }
        },
        cardsBlock: {
            cards: [
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle1.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle2.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle3.jpg"
                }
            ]
        },
        telegram: {
            // channel1: "Масалович и партнеры",
            // channel1Link: "https://t.me/maspartners",
            channel2: "Mycroft Intelligence",
            channel2Link: "https://t.me/mycroftintel"
        },
        social: {
            channel1: "Дзен КиберДед",
            channel1Link: "https://dzen.ru/kiberded",
            // channel2: "ВК Масалович и партнеры",
            // channel2Link: "https://vk.com/@mycroftintel-bspltn",
            channel3: "Дзен Mycroft Intelligence",
            channel3Link: "https://dzen.ru/mycroftintel"
        }
    }
};

export default contentRu;
