import React from "react";
import { useLanguage } from "../../src/hooks/useLang";

import CardsWithArrowsAdaptive from "../pageBlocks/cardsBlocks/cardsWithArrowsAdaptive";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const SafetyPage = () => {
    const { content } = useLanguage();
    const pageName = content.safety.pageName;
    const subMenuList = content.safety.subMenuList;
    const imageUrls = content.safety.imageUrls;
    const blocks = content.safety.blocks;
    const cards = content.safety.cards;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.main} />
            <HorizontalBlockAdaptive blockContent={blocks.economic} />
            <CardsWithArrowsAdaptive
                blockContent={cards.block1}
                arrowsDirection="left"
                topBorderbBlock={true}
            />
            {/* <HorizontalBlockAdaptive blockContent={blocks.support} />
            <CardsWithArrowsAdaptive
                blockContent={cards.block3}
                arrowsDirection="right"
                topBorderbBlock={true}
            /> */}
            <HorizontalBlockAdaptive blockContent={blocks.cyber} />
            <CardsWithArrowsAdaptive
                blockContent={cards.block2}
                arrowsDirection="left"
                topBorderbBlock={true}
            />
        </div>
    );
};

export default SafetyPage;
